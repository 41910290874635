import { Component, Input, OnInit } from "@angular/core";
import { IArtifact, IRelease } from "./models";
import { HttpRequestHelper } from "../common/http-request-helper";

@Component({
    selector: 'release-selection',
    template: `
    <div class="d-flex justify-content-left my-3 ml-md-5">
                    <div class="releases-container">
                        <label class="font-italic">{{header}}:</label>
                        <div class="input-group">
                            <select class="form-control noselect" [(ngModel)]="downloadUrl"
                                aria-label="vCMS API Version">
                                <option value="">-- Select Version --</option>
                                <ng-container *ngFor="let artifact of releases">
                                    <option [value]="artifact.asset.url">{{artifact.release_name || artifact.tag_name}}</option>
                                </ng-container>
                            </select>
                            <div class="input-group-append">
                                <a class="btn btn-gradient courtstack-gradient-btn"
                                    *ngIf="!!downloadUrl"
                                    [href]="downloadUrl">Download</a>
                                <button class="btn courtstack-gradient-btn" disabled
                                    *ngIf="!downloadUrl">Download</button>
                            </div>
                        </div>
                    </div>
                </div>
    `,
    styles: [
        `
        .releases-container select {
            min-width: 250px;
        }

        @media only screen 
        and (max-device-width : 400px) {
            .releases-container select {
                min-width: 220px;
            }
        }

        @media only screen 
        and (max-device-width : 350px) {
            .releases-container select {
                min-width: 180px;
            }
        }

        .releases-container select.noselect:focus {
           box-shadow:none;
           border-color: #ced4da;
        }

        button[disabled] {
            cursor: not-allowed;
        }

        `
    ]
})
export class ReleaseSelectionComponent implements OnInit{
    private PROXY_SERVER_URL = 'https://lasc-courtstack-installer.azurewebsites.net';
    private BASE_REPO_URL = `${this.PROXY_SERVER_URL}/repos/CourtStack`;

    @Input() header: string;
    @Input() repositoryName: string;

    releases: IArtifact[] = [];
    downloadUrl = "";

    constructor(private readonly requestHelper: HttpRequestHelper){}

    async ngOnInit() {
        await this.setupAssets();
    }

    private async setupAssets() {
            const releases = await this.requestHelper.get<IRelease[]>(`${this.BASE_REPO_URL}/${this.repositoryName}/releases`, { headers: { 'Accept': 'application/vnd.github.v3+json', 'Content-Type': 'application/json' } });
            releases.sort((a, b) => new Date(b.published_at).valueOf() - new Date(a.published_at).valueOf());
            releases.forEach(release => {
                const zipFile = release.assets.find(asset => asset.name.endsWith('.zip'));
                if (zipFile) {
                    zipFile.url = zipFile.url.replace('https://api.github.com', this.PROXY_SERVER_URL) + '?download=true';
                    this.releases.push({
                        id: release.id,
                        release_name: release.name,
                        tag_name: release.tag_name,
                        published_at: release.published_at,
                        asset: zipFile
                    });
                }
            })
        }
}