<section class="background-gradient">
    <div class="container p-4">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="p-4 text-white text-center">
                    vCMS Installation
                </h2>
            </div>
        </div>
    </div>
</section>
<section class="installation">
    <div class="container-fluid p-5">
        <div class="row col-lg-12">
            <div class="col-lg-6" id="installer">
                <fieldset>
                    <legend class="px-5 py-1">
                        <span class="d-block clearfix"><span>Automated Install</span> <span
                                class="badge badge-secondary font-italic ml-3">Recommended</span></span>
                    </legend>
                    <div class="d-flex justify-content-center">
                        <div class="instructions-container">
                            <div class="step">
                                <div class="v-stepper">
                                    <div class="circle">
                                        <span>1</span>
                                    </div>
                                    <div class="line"></div>
                                </div>
                                <div class="content">
                                    <span>Click to download the <a style="text-decoration:underline;"
                                            href="https://bit.ly/41Ux5lT">vCMS
                                            installer.</a></span>
                                </div>
                            </div>
                            <div class="step">
                                <div class="v-stepper">
                                    <div class="circle">
                                        <span>2</span>
                                    </div>
                                    <div class="line"></div>
                                </div>
                                <div class="content">
                                    Run the downloaded installer, with administrator rights.
                                </div>
                            </div>
                            <div class="step final-step">
                                <div class="v-stepper">
                                    <div class="circle">
                                        <span>3</span>
                                    </div>
                                    <div class="line"></div>
                                </div>
                                <div class="content">
                                    Follow the onscreen prompts to complete the installation.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 py-3">
                        A detailed guide on how to install vCMS can be found here <a
                            href="https://courtstack.org/CourtStack-Docs/#/automated-installer-usage-guide">vCMS
                            Installation Guide</a>.
                    </div>
                </fieldset>
            </div>

            <div class="col-lg-6 manual-download-container mt-sm-2 mt-md-0" id="manual-installation">
                <fieldset>
                    <legend class="px-5 py-1 text-muted">Manual Install</legend>
                    <div class="pl-md-5">
                        <release-selection header="vCMS API" repositoryName="Vcms-Api-Rest"></release-selection>
                        <release-selection header="ECourt Plugin"
                            repositoryName="Vcms-Plugins-eCourt"></release-selection>
                        <release-selection header="Odyssey Plugin"
                            repositoryName="Vcms-Plugins-Odyssey"></release-selection>
                        <release-selection header="Odyssey Interceptor"
                            repositoryName="Vcms-Interceptors-Odyssey-Externals"></release-selection>
                        <release-selection header="Odyssey Proxy"
                            repositoryName="Vcms-Proxy-Odyssey"></release-selection>
                        <release-selection header="Redline Plugin"
                            repositoryName="Vcms-Plugins-Redline"></release-selection>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</section>