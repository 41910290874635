import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { retry } from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class HttpRequestHelper {
    constructor(private readonly httpClient: HttpClient) {}

    get<T>(url: string, options?: any): Promise<T> {
        return <any>(this.httpClient.get<T>(url,options)
        .pipe(retry(2))
        .toPromise());
    }
}