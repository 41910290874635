import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { TeamComponent } from './team/team.component';
import { MediaComponent } from './media/media.component';
import { ReleaseComponent } from './release/release.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentationComponent } from './documentation/documentation.component';
import { DocumentationViewerComponent } from './documentation-viewer/documentation-viewer.component';
import { LearnComponent } from './learn/learn.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { RedirectGuard } from './redirect/redirect.guard';
import { InstallationComponent } from './installation/installation.component';
import { HttpRequestHelper } from './common/http-request-helper';
import { ReleaseSelectionComponent } from './installation/release-selection.component';
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    AboutComponent,
    HomeComponent,
    FooterComponent,
    TeamComponent,
    MediaComponent,
    ReleaseComponent,
    SolutionsComponent,
    DocumentationComponent,
    DocumentationViewerComponent,
    LearnComponent,
    InstallationComponent,
    ReleaseSelectionComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbDropdownModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    HttpRequestHelper,
    {
      provide: APP_BASE_HREF,
      useFactory: (platform: PlatformLocation) => platform.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    }, RedirectGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
